<template>
  <v-row>
    <v-col>
      <v-item-group v-model="currentStep" id="tabButtons" class="v-item-group text-center mx-5 mb-3 ml-0 mr-0" mandatory v-if="!client.isClient">
        <v-item v-slot="{ active }">
          <v-btn tile elevation="0" :class="{ active: active }" :width="itemButtonSize"
            @click="changeStep(stepEnum.DADOS, 'Editar Cliente')">
            {{ stepName[stepEnum.DADOS] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn tile elevation="0" :class="{ active: active }" :width="itemButtonSize"
          @click="changeStep(stepEnum.PARAMETRIZACAO, 'Editar Cliente')">
            {{ stepName[stepEnum.PARAMETRIZACAO] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn tile elevation="0" :class="{ active: active }" :width="itemButtonSize"
            @click="changeStep(stepEnum.UNIDADE, 'Unidades')" :disabled="!isEditMode">
            {{ stepName[stepEnum.UNIDADE] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn tile elevation="0" :class="{ active: active }" :width="itemButtonSize"
            @click="changeStep(stepEnum.ALIQUOTA, 'Alíquota CSLL')" :disabled="!isEditMode">
            {{ stepName[stepEnum.ALIQUOTA] }}
          </v-btn>
        </v-item>
        <v-item v-slot="{ active }">
          <v-btn tile elevation="0" :class="{ active: active }" :width="itemButtonSize"
            @click="changeStep(stepEnum.REGULARIDADE, 'Regularidade Fiscal')" :disabled="!isEditMode">
            {{ stepName[stepEnum.REGULARIDADE] }}
          </v-btn>
        </v-item>
      </v-item-group>
      <v-window v-model="currentStep">
        <!-- DADOS -->
        <v-window-item eager>
          <v-card class="teros-elevation">
            <v-card-title class="px-5 text-uppercase v-title-cadastro">
              Cadastro de Cliente
            </v-card-title>
            <v-card-text class="px-5 v-form-cadastro">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5">
                      <input-v v-model="cliente.razao_social" :valid.sync="fields.razao_social.valid"
                        :label="fields.razao_social" :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.fantasia" :valid.sync="fields.fantasia.valid" :label="fields.fantasia"
                        :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="3">
                      <input-v v-model="cliente.tipo_empresa" :valid.sync="fields.tipo_empresa.valid"
                        :label="fields.tipo_empresa" :opts="opts" :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.cnpj" :valid.sync="fields.cnpj.valid" :label="fields.cnpj"
                        :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.ie" :valid.sync="fields.ie.valid" :label="fields.ie"
                        :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.im" :valid.sync="fields.im.valid" :label="fields.im"
                        :editable="!isSend && !client.isClient"></input-v>
                    </v-col>
                    <v-col cols="6">
                      <input-v v-model="cliente.endereco" :valid.sync="fields.endereco.valid" :label="fields.endereco"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="2">
                      <input-v v-model="cliente.numero" :valid.sync="fields.numero.valid" :label="fields.numero"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.complemento" :valid.sync="fields.complemento.valid"
                        :label="fields.complemento" :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.bairro" :valid.sync="fields.bairro.valid" :label="fields.bairro"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.cidade" :valid.sync="fields.cidade.valid" :label="fields.cidade"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.uf" :valid.sync="fields.uf.valid" :label="fields.uf" :editable="!isSend"
                        :opts="opts"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.cep" :valid.sync="fields.cep.valid" :label="fields.cep"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.fone" :valid.sync="fields.fone.valid" :label="fields.fone"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.cel" :valid.sync="fields.cel.valid" :label="fields.cel"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="6">
                      <input-v v-model="cliente.email" :valid.sync="fields.email.valid" :label="fields.email"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="6">
                      <input-v v-model="cliente.site" :valid.sync="fields.site.valid" :label="fields.site"
                        :editable="!isSend"></input-v>
                    </v-col>
                    <template v-if="!client.isClient">
                      <v-col cols="5">
                        <input-v v-model="cliente.regime_tributario" :valid.sync="fields.regime_tributario.valid"
                          :label="fields.regime_tributario" :editable="!isSend" :opts="opts"></input-v>
                      </v-col>
                      <v-col cols="4">
                        <input-v v-model="cliente.desoneracao_folha" :valid.sync="fields.desoneracao_folha.valid"
                          :label="fields.desoneracao_folha" :editable="!isSend" :opts="opts"></input-v>
                      </v-col>
                    </template>
                    <v-col cols="3" v-if="!client.isClient">
                      <input-v v-model="cliente.contaContabil" :valid.sync="fields.contaContabil.valid" :label="fields.contaContabil"
                        :editable="!isSend" :opts="opts"></input-v>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-5 pb-4 pt-0">
              <v-spacer></v-spacer>
              <v-btn depressed @click="doBack()" color="secondary" dark class="pr-4">
                <v-icon left>mdi-chevron-left</v-icon>Cancelar
              </v-btn>
              <v-dialog v-if="showDelete" v-model="deleteDialog" persistent max-width="390">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark depressed color="accent" v-bind="attrs" v-on="on" class="px-4 ml-2">
                    Excluir
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title style="display: block" class="text-center">
                    Exclusão de {{ cliente.fantasia }}
                  </v-card-title>
                  <v-card-text>
                    Deseja realmente excluir o cliente:
                    <br />
                    {{ cliente.fantasia }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" dark depressed @click="deleteDialog = false">
                      Não
                    </v-btn>
                    <v-btn color="primary" dark depressed @click="
                      doDelete();
                    deleteDialog = false;
                    ">
                      Sim
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn depressed @click="doSave()" color="primary" dark class="px-4">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
        <!-- PARAMETRIZAÇÃO -->
        <v-window-item eager>
          <v-card class="teros-elevation">
            <v-card-title class="text-uppercase v-title-cadastro">Parametrização</v-card-title>
            <v-card-text class="v-form-cadastro">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4">
                      <input-v v-model="cliente.ativo" :valid.sync="fields.ativo.valid" :label="fields.ativo"
                        :editable="doActions" :opts="opts"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.corporacao_id" :valid.sync="fields.corporacao_id.valid"
                        :label="fields.corporacao_id" :opts="opts" :editable="!isSend"></input-v>
                    </v-col>
                    <v-col cols="4">
                      <input-v v-model="cliente.trabalho" :valid.sync="fields.trabalho.valid" :label="fields.trabalho"
                        :editable="!isSend" :opts="opts"></input-v>
                    </v-col>
                    <v-col cols="6">
                      <input-v v-model="cliente.regiao" :valid.sync="fields.regiao.valid" :label="fields.regiao"
                        :editable="!isSend" :opts="opts"></input-v>
                    </v-col>
                    <v-col cols="6">
                      <input-v v-model="cliente.intervaloAlerta" :valid.sync="fields.intervaloAlerta.valid"
                        :label="fields.intervaloAlerta" :editable="!isSend" :opts="opts"></input-v>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <template v-if="!client.isClient">
                <v-row>
                  <v-col class="v-label-input font-weight-medium pl-5">
                    Sistema de Importação
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-sheet outlined rounded class="px-2 mt-2 py-2">
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.sistema" :valid.sync="fields.sistema.valid" :label="fields.sistema"
                            :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaFopag" :valid.sync="fields.diaAlertaFopag.valid"
                            :label="fields.diaAlertaFopag" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.sistemaUserId" :valid.sync="fields.sistemaUserId.valid"
                            :label="fields.sistemaUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.provisao" :valid.sync="fields.provisao.valid"
                            :label="fields.provisao" :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaProvisoes" :valid.sync="fields.diaAlertaProvisoes.valid"
                            :label="fields.diaAlertaProvisoes" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.provisaoUserId" :valid.sync="fields.provisaoUserId.valid"
                            :label="fields.provisaoUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.ponto" :valid.sync="fields.ponto.valid" :label="fields.ponto"
                            :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaPonto" :valid.sync="fields.diaAlertaPonto.valid"
                            :label="fields.diaAlertaPonto" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.pontoUserId" :valid.sync="fields.pontoUserId.valid"
                            :label="fields.pontoUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.timesheet" :valid.sync="fields.timesheet.valid"
                            :label="fields.timesheet" :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaTimesheet" :valid.sync="fields.diaAlertaTimesheet.valid"
                            :label="fields.diaAlertaTimesheet" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.timesheetUserId" :valid.sync="fields.timesheetUserId.valid"
                            :label="fields.timesheetUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.materiais" :valid.sync="fields.materiais.valid"
                            :label="fields.materiais" :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaMateriais" :valid.sync="fields.diaAlertaMateriais.valid"
                            :label="fields.diaAlertaMateriais" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.materiaisUserId" :valid.sync="fields.materiaisUserId.valid"
                            :label="fields.materiaisUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.consumo" :valid.sync="fields.consumo.valid" :label="fields.consumo"
                            :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaConsumo" :valid.sync="fields.diaAlertaConsumo.valid"
                            :label="fields.diaAlertaConsumo" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.consumoUserId" :valid.sync="fields.consumoUserId.valid"
                            :label="fields.consumoUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.terceiros" :valid.sync="fields.terceiros.valid"
                            :label="fields.terceiros" :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaTerceiros" :valid.sync="fields.diaAlertaTerceiros.valid"
                            :label="fields.diaAlertaTerceiros" :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.terceirosUserId" :valid.sync="fields.terceirosUserId.valid"
                            :label="fields.terceirosUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <input-v v-model="cliente.faturamento" :valid.sync="fields.faturamento.valid"
                            :label="fields.faturamento" :editable="false"></input-v>
                        </v-col>
                        <v-col cols="2">
                          <input-v v-model="cliente.diaAlertaFaturamento"
                            :valid.sync="fields.diaAlertaFaturamento.valid" :label="fields.diaAlertaFaturamento"
                            :editable="!isSend" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="7">
                          <input-v v-model="cliente.faturamentoUserId" :valid.sync="fields.faturamentoUserId.valid"
                            :label="fields.faturamentoUserId" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <input-v v-model="cliente.temAjuste" :valid.sync="fields.temAjuste.valid"
                            :label="fields.temAjuste" :editable="false" :opts="opts"></input-v>
                        </v-col>
                        <v-col cols="6">
                          <input-v v-model="cliente.criacaoFuncionariosRestricao"
                            :valid.sync="fields.criacaoFuncionariosRestricao.valid"
                            :label="fields.criacaoFuncionariosRestricao" :editable="false" :opts="opts"></input-v>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
            <v-card-actions class="px-5 pb-4 pt-0">
              <v-spacer></v-spacer>
              <v-btn depressed @click="doBack()" color="secondary" dark class="pr-4">
                <v-icon left>mdi-chevron-left</v-icon>Cancelar
              </v-btn>
              <!-- <v-dialog v-if="showDelete" v-model="deleteDialog" persistent max-width="390">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    depressed
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    class="px-4 ml-2"
                  >
                    Excluir
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title style="display: block" class="text-center">
                    Exclusão de {{ cliente.fantasia }}
                  </v-card-title>
                  <v-card-text>
                    Deseja realmente excluir o cliente:
                    <br />
                    {{ cliente.fantasia }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      dark
                      depressed
                      @click="deleteDialog = false"
                    >
                      Não
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      depressed
                      @click="
                        doDelete();
                        deleteDialog = false;
                      "
                    >
                      Sim
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
              <v-btn depressed @click="doSave()" color="primary" dark class="px-4">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
        <!-- UNIDADE -->
        <v-window-item eager>
          <v-card class="teros-elevation">
            <v-card-title class="px-5 text-uppercase v-title-cadastro">Unidades</v-card-title>
            <v-card-text class="p-0">
              <UnidadesComponent :selectedClient="cliente.id"></UnidadesComponent>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-window-item>
        <!-- ALÍQUOTA CSLL -->
        <v-window-item eager>
          <v-card class="teros-elevation">
            <v-card-title class="px-5 text-uppercase v-title-cadastro">
              Alíquota CSLL
            </v-card-title>
            <v-card-text class="p-0">
              <CSLLComponent :selectedClient="cliente.id"></CSLLComponent>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-window-item>
        <!-- REGULARIDADE FISCAL -->
        <v-window-item eager>
          <v-card class="teros-elevation">
            <v-card-title class="px-5 text-uppercase v-title-cadastro">Regularidade Fiscal</v-card-title>
            <v-card-text class="p-0">
              <RegularidadeFiscalComponent></RegularidadeFiscalComponent>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-col>
    <v-col cols="2" style="min-width: 350px" class="d-flex flex-column">
      <v-card class="teros-elevation">
        <v-card-subtitle v-if="!client.isClient" class="pb-1 text-center">
          Selecione os Consultores
        </v-card-subtitle>
        <v-card-subtitle v-else class="pb-1 text-center">
          Conheça os consultores da Galapos responsáveis por seu contrato
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <span v-if="!client.isClient" class="v-label-input">
            Consultor Tributário
          </span>
          <v-avatar size="105" class="d-block mx-auto my-5">
            <img :src="consultorTributario && consultorTributario.foto
              ? consultorTributario.foto
              : $userDefaultImgBase64
              " />
          </v-avatar>
          <input-v v-if="!client.isClient" v-model="cliente.consultorId" :valid.sync="fields.consultorId.valid"
            :label="fields.consultorId" :opts="opts" :editable="!isSend && !client.isClient" :hideDetails="true"
            class="mb-7" @input="updateSelectedEquipe()"></input-v>
          <template v-else>
            <span class="v-label-input d-block mt-3">
              {{ consultorTributario && consultorTributario.nome }}
            </span>
            <span class="v-label-input d-block mt-2 font-weight-bold">
              Consultor Tributário
            </span>
            <span class="v-label-input d-block mt-3">
              <v-icon left>mdi-email-outline</v-icon>
              {{ consultorTributario && consultorTributario.email }}
            </span>
            <span class="v-label-input d-block mt-2">
              <v-icon left>mdi-phone-outline</v-icon>
              {{ consultorTributario && consultorTributario.telefone }}
            </span>
          </template>

          <span v-if="!client.isClient" class="v-label-input">
            Consultor de Projetos
          </span>
          <v-avatar size="105" class="d-block mx-auto my-5">
            <img :src="consultorProjetos && consultorProjetos.foto
              ? consultorProjetos.foto
              : $userDefaultImgBase64
              " />
          </v-avatar>
          <input-v v-if="!client.isClient" v-model="cliente.consultorProjetosId"
            :valid.sync="fields.consultorProjetosId.valid" :label="fields.consultorProjetosId" :opts="opts"
            :editable="!isSend && !client.isClient" :hideDetails="true" class="mb-7"
            @input="updateSelectedEquipe()"></input-v>
          <template v-else>
            <span class="v-label-input d-block mt-3">
              {{ consultorProjetos && consultorProjetos.nome }}
            </span>
            <span class="v-label-input d-block mt-2 font-weight-bold">
              Consultor de Projetos
            </span>
            <span class="v-label-input d-block mt-3">
              <v-icon left>mdi-email-outline</v-icon>
              {{ consultorProjetos && consultorProjetos.email }}
            </span>
            <span class="v-label-input d-block mt-2">
              <v-icon left>mdi-phone-outline</v-icon>
              {{ consultorProjetos && consultorProjetos.telefone }}
            </span>
          </template>

          <span v-if="!client.isClient" class="v-label-input">
            Revisor de Projetos
          </span>
          <v-avatar v-if="!client.isClient" size="105" class="d-block mx-auto my-5">
            <img :src="revisorProjetos && revisorProjetos.foto
              ? revisorProjetos.foto
              : $userDefaultImgBase64
              " />
          </v-avatar>
          <input-v v-if="!client.isClient" v-model="cliente.revisorProjetosId"
            :valid.sync="fields.revisorProjetosId.valid" :label="fields.revisorProjetosId" :opts="opts"
            :editable="!isSend && !client.isClient" :hideDetails="true" class="mb-7"
            @input="updateSelectedEquipe()"></input-v>

          <span v-if="!client.isClient" class="v-label-input">
            Revisor Tributário
          </span>
          <v-avatar v-if="!client.isClient" size="105" class="d-block mx-auto my-5">
            <img :src="revisorTributario && revisorTributario.foto
              ? revisorTributario.foto
              : $userDefaultImgBase64
              " />
          </v-avatar>
          <input-v v-if="!client.isClient" v-model="cliente.revisorTributarioId"
            :valid.sync="fields.revisorTributarioId.valid" :label="fields.revisorTributarioId" :opts="opts"
            :editable="!isSend && !client.isClient" :hideDetails="true" class="mb-7"
            @input="updateSelectedEquipe()"></input-v>

          <template v-if="!client.isClient">
            <v-card-subtitle v-if="!client.isClient" class="pb-1 text-center">
              Equipe de apoio
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-autocomplete chips clearable deletable-chips dense hide-details :items="outrosConsultores"
              item-text="nome" item-value="id" label="" multiple outlined placeholder="Selecione um ou mais consultores"
              persistent-placeholder :search-input.sync="autocompleteFilterValue" v-model="cliente.equipe"
              @input="autocompleteFilterValue = ''"></v-autocomplete>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import { mapGetters } from "vuex";
import { cloneDeep, sortBy } from "lodash";
import { UserTypeEnum } from '@/core/enums/user-types'
import CSLLComponent from "@/templates/cadastro/csll.vue";
import UnidadesComponent from "@/templates/cadastro/cliente.unidades.vue";
import RegularidadeFiscalComponent from "@/templates/cadastro/regularidade-fiscal.vue";

export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
    CSLLComponent,
    UnidadesComponent,
    RegularidadeFiscalComponent
  },
  created: function () {
    this.loadUsuarios();
    this.resourceGruposEmpresa.get().then((response) => (this.opts.gruposDeEmpresa = [{ id: 0, nome: "Nenhum" }].concat(response)));
    this.resourceConsultores.get({ query: `empresaId=${this.empresaId || this.client.clientId}` }).then((response) => {
      if (!response.error) {
        this.opts.consultores = sortBy(response.consultores, "nome");
      }
    });
    this.doLoad();
  },
  // watch: {
  //   "$route.params.empresaId": function () {
  //     this.doLoad();
  //   },
  //   "$route.query.clientId": function () {
  //     this.$router.push({
  //       params: { clientId: this.$route.query.clientId },
  //       query: { clientId: this.$route.query.clientId },
  //     });
  //   },
  // },
  computed: {
    ...mapGetters({
      userData: "jwtData",
      user: "user",
    }),
    client: function () {
      return this.getClient();
    },
    empresaId: function () {
      return this.isEditMode && this.$route.params.empresaId;
    },
    isEditMode: function () {
      return this.$route.meta.mode === 'update';
    },
    resource: function () {
      return this.apiResource(`/v1/clientes`);
    },
    resourceConsultores: function () {
      return this.apiResource(`/v1/consultores`);
    },
    resourceGruposEmpresa: function () {
      return this.apiResource(
        `/v1/clientes/${this.client.clientId}/corporacao`
      );
    },
    resourceUsuario: function () {
      return this.apiResource(`/v1/acesso/${this.cliente.id}/controleacesso`);
    },
    showDelete: function () {
      return this.isEditMode && this.userHasAccessToAction('delete');
    },
    fields: function () {
      var fields = {};
      this.cols.forEach((col) => {
        fields[col.key] = col;
      });
      return fields;
    },
    consultorProjetos: function () {
      return this.opts.consultores.find(
        (c) => c.id == this.cliente.consultorProjetosId
      );
    },
    revisorProjetos: function () {
      return this.opts.consultores.find(
        (c) => c.id == this.cliente.revisorProjetosId
      );
    },
    revisorTributario: function () {
      return this.opts.consultores.find(
        (c) => c.id == this.cliente.revisorTributarioId
      );
    },
    consultorTributario: function () {
      return this.opts.consultores.find(
        (c) => c.id == this.cliente.consultorId
      );
    },
    outrosConsultores: function () {
      const {
        revisorProjetosId,
        revisorTributarioId,
        consultorId,
        consultorProjetosId,
      } = this.cliente;
      const revisores = [
        revisorProjetosId,
        revisorTributarioId,
        consultorId,
        consultorProjetosId,
      ].map((id) => parseInt(id, 10));
      return this.opts.consultores.filter(
        (consultor) => !revisores.includes(consultor.id)
      );
    },
    doActions() {
      return [UserTypeEnum.ADMINISTRADOR].includes(this.user.tipo_usuario)
    },
    stepEnum: function () {
      return {
        DADOS: 0,
        PARAMETRIZACAO: 1,
        UNIDADE: 2,
        ALIQUOTA: 3,
        REGULARIDADE: 4
      };
    },
    stepName: function () {
      return ({
        [this.stepEnum.DADOS]: 'Dados',
        [this.stepEnum.PARAMETRIZACAO]: 'Parametrização',
        [this.stepEnum.UNIDADE]: 'Unidades',
        [this.stepEnum.ALIQUOTA]: 'Alíquota CSLL',
        [this.stepEnum.REGULARIDADE]: 'Regularidade Fiscal',
      });
    },
    itemButtonSize: function () {
      const numButtons = Object.keys(this.stepEnum).length;
      return `${100 / numButtons}%`;
    }
  },
  methods: {
    updateSelectedEquipe() {
      const {
        revisorProjetosId,
        revisorTributarioId,
        consultorId,
        consultorProjetosId,
      } = this.cliente;
      const revisores = [
        revisorProjetosId,
        revisorTributarioId,
        consultorId,
        consultorProjetosId,
      ].map((id) => parseInt(id, 10));
      const equipe = this.cliente.equipe || [];
      this.cliente.equipe = equipe.filter(({ id }) => !revisores.includes(id));
    },
    isValidForm() {
      let validationList = Object.entries(this.fields)
        .map((s) => s[1])
        .filter((i) => !i.hideInform && i.valid)

      var invalid = validationList.find((l) => !l.valid())
      this.fieldError = invalid;
      return !invalid;
    },
    parseArrayId: function (string) {
      const obj = string && string != "" ? string : "[]";
      return JSON.parse(obj);
    },
    doLoad: function () {
      if (this.isEditMode) {
        this.$route.meta.pageTitle = 'Editar Cliente';
        return this.resource
          .get(this.empresaId || this.client.clientId)
          .then((response = {}) => {
            this.cliente = response;
            this.cliente.sistemaUserId = this.parseArrayId(
              response.sistemaUserId
            );
            this.cliente.provisaoUserId = this.parseArrayId(
              response.provisaoUserId
            );
            this.cliente.timesheetUserId = this.parseArrayId(
              response.timesheetUserId
            );
            this.cliente.materiaisUserId = this.parseArrayId(
              response.materiaisUserId
            );
            this.cliente.consumoUserId = this.parseArrayId(
              response.consumoUserId
            );
            this.cliente.terceirosUserId = this.parseArrayId(
              response.terceirosUserId
            );
            this.cliente.faturamentoUserId = this.parseArrayId(
              response.faturamentoUserId
            );
            this.cliente.pontoUserId = this.parseArrayId(response.pontoUserId);

            if (!this.cliente.corporacao_id) {
              this.cliente.corporacao_id = 0;
            }
            this.cliente.regime_tributario = parseInt(this.cliente.regime_tributario)
            this.loadUsuarios();
          });
      }
    },
    loadUsuarios: function () {
      if (this.client.isClient || !this.cliente?.id) {
        return;
      }

      return this.resourceUsuario.get().then((response) => {
        this.opts.usuarios = response
          .filter(({ ativo }) => ativo)
          .map((user) => ({
            ...user,
            nome_email: `${user.nome} (${user.email})`,
          }));

        return this.opts.usuarios;
      });
    },
    doSave: function () {
      if (this.isValidForm()) {
        const client = cloneDeep(this.cliente);

        if (client.corporacao_id === 0) {
          client.corporacao_id = null;
        }

        this.resource.save(client, client.id).then((response) => {
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: this.isEditMode ? "Alterações salvas" : "Empresa adicionada ao Teros",
            text: "",
          });

          if (this.isEditMode) {
            this.$router.go(0);
          } else {
            this.$router.push({ name: "edicao-empresa", params: { empresaId: response.id } });
          }
        });
      } else {
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "error",
          title: "Erro",
          text: `Preencha todos os campos obrigatórios. Campo ${this.fieldError.name} está com erro  ${this.fieldError.tab ? 'na aba <b>' + this.fieldError.tab + '</b>': ""}`,
        });
      }
    },
    doDelete: function () {
      this.resource.delete(this.cliente.id).then(() => {
        this.doBack();
      });
    },
    doBack: function () {
      this.$router.push({ name: "listagem-empresas" });
    },
    changeStep(step, pageTitle) {
      this.currentStep = step;
      this.$route.meta.pageTitle = pageTitle;
    }
  },
  data: function () {
    return {
      isSend: false,
      deleteDialog: false,
      cols: [
        /* { key: "id", name: "ID", size: 100, sort: 0, show: true }, */
        {
          key: "fantasia",
          name: "Fantasia",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          tab: 'Dados'
        },
        {
          key: "razao_social",
          name: "Razão Social",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          tab: 'Dados'
        },
        {
          key: "corporacao_id",
          name: "Grupo de Empresas",
          type: this.$fieldTypes.SELECT,
          rel: { to: "gruposDeEmpresa", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          tab: 'Parametrização'
        },
        {
          key: "cnpj",
          name: "CNPJ",
          type: this.$fieldTypes.CNPJ,
          nowrap: true,
          rules: [{ rule: "required" }],
          tab: 'Dados'
        },
        { key: "ie", name: "Inscrição Estadual", type: this.$fieldTypes.TEXT },
        { key: "im", name: "Inscrição Municipal", type: this.$fieldTypes.TEXT },
        {
          key: "tipo_empresa",
          name: "Tipo Empresa",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tiposEmpresa", key: "id", name: "nome" }
        },
        { key: "endereco", name: "Endereço", type: this.$fieldTypes.TEXT },
        { key: "numero", name: "Número", type: this.$fieldTypes.TEXT  },
        {
          key: "complemento",
          name: "Complemento",
          type: this.$fieldTypes.TEXT
        },
        { key: "bairro", name: "Bairro", type: this.$fieldTypes.TEXT },
        { key: "cidade", name: "Cidade", type: this.$fieldTypes.TEXT },
        { key: "cep", name: "CEP", type: this.$fieldTypes.TEXT },
        {
          key: "uf",
          name: "UF",
          type: this.$fieldTypes.SELECT,
          rel: { to: "ufs", key: "id", name: "nome" }
        },
        {
          key: "fone",
          name: "Telefone Comercial",
          type: this.$fieldTypes.TEXT
        },
        { key: "cel", name: "Celular", type: this.$fieldTypes.TEXT },
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "email" }],
          tab: 'Dados'
        },
        { key: "site", name: "Site", type: this.$fieldTypes.TEXT },
        /* { key: "????", name: "Líder de Projeto", type: this.$fieldTypes.TEXT }, */
        {
          key: "trabalho",
          name: "Benefício",
          type: this.$fieldTypes.SELECT,
          rel: { to: "trabalho", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          tab: 'Parametrização'
        },
        {
          key: "intervaloAlerta",
          name: "Intervalo de Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "intervalosAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaFopag",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaProvisoes",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaTimesheet",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaPonto",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaMateriais",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaConsumo",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaTerceiros",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        {
          key: "diaAlertaFaturamento",
          name: "Dia do Alerta",
          type: this.$fieldTypes.SELECT,
          rel: { to: "diasAlerta", key: "value", name: "name" },
        },
        { key: "sistema", name: "Fopag", type: this.$fieldTypes.NUMBER },
        { key: "provisao", name: "Provisões", type: this.$fieldTypes.NUMBER },
        { key: "ponto", name: "Ponto", type: this.$fieldTypes.NUMBER },
        { key: "timesheet", name: "Timesheet", type: this.$fieldTypes.NUMBER },
        { key: "materiais", name: "Materiais", type: this.$fieldTypes.NUMBER },
        {
          key: "consumo",
          name: "Con. Materiais",
          type: this.$fieldTypes.NUMBER,
        },
        {
          key: "terceiros",
          name: "Serv. Terceiros",
          type: this.$fieldTypes.NUMBER,
        },
        {
          key: "faturamento",
          name: "Faturamento",
          type: this.$fieldTypes.NUMBER,
        },
        {
          key: "sistemaUserId",
          name: "Responsável Fopag",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "provisaoUserId",
          name: "Responsável Provisões",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "pontoUserId",
          name: "Responsável Ponto",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "timesheetUserId",
          name: "Responsável Timesheet",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "materiaisUserId",
          name: "Responsável Materiais",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "consumoUserId",
          name: "Responsável Consumo Materiais",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "terceirosUserId",
          name: "Responsável Serviço de Terceiros",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "faturamentoUserId",
          name: "Responsável Faturamento",
          type: this.$fieldTypes.AUTOCOMPLETE_MULTIPLE,
          rel: { to: "usuarios", key: "id", name: "nome_email" },
        },
        {
          key: "regime_tributario",
          name: "Regime Tributário",
          type: this.$fieldTypes.SELECT,
          rel: { to: "regimes", key: "id", name: "nome" },
        },
        {
          key: "desoneracao_folha",
          name: "Desoneração de Folha",
          type: this.$fieldTypes.SELECT,
          rel: { to: "desoneracao", key: "id", name: "nome" },
        },
        {
          key: "consultorId",
          name: "Consultor Tributário",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "consultores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "consultorProjetosId",
          name: "Consultor de Projetos",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "consultores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "revisorProjetosId",
          name: "Revisor de Projetos",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "consultores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "revisorTributarioId",
          name: "Revisor Tributário",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "consultores", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "regiao",
          name: "Região",
          type: this.$fieldTypes.SELECT,
          rel: { to: "regioes", key: "nome", name: "nome" },
          rules: [{ rule: "required" }],
          tab: 'Parametrização'
        },
        {
          key: "ativo",
          name: "Ativo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "isAtivo", key: "id", name: "nome" },
          rules: [{ rule: "required" }],
          tab: 'Parametrização'
        },
        {
          key: "temAjuste",
          name: "Utiliza fórmulas para cálculo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "isAjuste", key: "id", name: "nome" },
          colSize: 6
        },
        {
          key: "criacaoFuncionariosRestricao",
          name: "Restrição para criação de colaboradores",
          type: this.$fieldTypes.SELECT,
          rel: { to: "isCriacaoFuncionarios", key: "id", name: "nome" },
          colSize: 6
        },
        {
          key: "contaContabil",
          name: "Conta Contábil",
          type: this.$fieldTypes.SELECT,
          rel: { to: "contabil", key: "id", name: "nome" },
          rules: [{ rule: "required"}],
          tab: 'Dados'
        }
      ],
      opts: {
        consultores: [],
        usuarios: [],
        gruposDeEmpresa: [],
        isCriacaoFuncionarios: [
          { id: "S", nome: "Sim" },
          { id: "N", nome: "Não" }
        ],
        isAjuste: [
          { id: "S", nome: "Sim" },
          { id: "N", nome: "Não" }
        ],
        isAtivo: [
          { id: "S", nome: "Sim" },
          { id: "N", nome: "Não" }
        ],
        tiposEmpresa: [
          { id: 1, nome: "MEI" },
          { id: 2, nome: "LTDA" },
          { id: 3, nome: "SA" },
          { id: 4, nome: "Universidade" },
        ],
        trabalho: [
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
          {
            id: BeneficiosEnum.CAPTACAO,
            nome: BeneficiosLabels.CAPTACAO,
          },
        ],
        regimes: [
          { id: 1, nome: "Lucro Real Anual" },
          { id: 2, nome: "Lucro Real Trimestral" },
          { id: 3, nome: "Lucro Presumido" },
          { id: 4, nome: "Simples Nacional" },
        ],
        regioes: [{ nome: "SUDAM/SUDENE" }, { nome: "Outras Regiões" }],
        desoneracao: [
          { id: "sim", nome: "Sim" },
          { id: "nao", nome: "Não" },
        ],
        ufs: [
          { id: "AC", nome: "Acre" },
          { id: "AL", nome: "Alagoas" },
          { id: "AP", nome: "Amapá" },
          { id: "AM", nome: "Amazonas" },
          { id: "BA", nome: "Bahia" },
          { id: "CE", nome: "Ceará" },
          { id: "DF", nome: "Distrito Federal" },
          { id: "ES", nome: "Espírito Santo" },
          { id: "GO", nome: "Goías" },
          { id: "MA", nome: "Maranhão" },
          { id: "MT", nome: "Mato Grosso" },
          { id: "MS", nome: "Mato Grosso do Sul" },
          { id: "MG", nome: "Minas Gerais" },
          { id: "PA", nome: "Pará" },
          { id: "PB", nome: "Paraíba" },
          { id: "PR", nome: "Paraná" },
          { id: "PE", nome: "Pernambuco" },
          { id: "PI", nome: "Piauí" },
          { id: "RJ", nome: "Rio de Janeiro" },
          { id: "RN", nome: "Rio Grande do Norte" },
          { id: "RS", nome: "Rio Grande do Sul" },
          { id: "RO", nome: "Rondônia" },
          { id: "RR", nome: "Roraíma" },
          { id: "SC", nome: "Santa Catarina" },
          { id: "SP", nome: "São Paulo" },
          { id: "SE", nome: "Sergipe" },
          { id: "TO", nome: "Tocantins" },
        ],
        intervalosAlerta: [
          { value: "nenhum", name: "Nenhum" },
          { value: "diario", name: "Diário" },
          { value: "semanal", name: "Semanal" },
          { value: "mensal", name: "Mensal" },
        ],
        diasAlerta: [
          { value: 0, name: "" },
          { value: 1, name: "1" },
          { value: 3, name: "3" },
          { value: 5, name: "5" },
          { value: 10, name: "10" },
          { value: 15, name: "15" },
          { value: 25, name: "25" },
        ],
        contabil: [
          { id: "S", nome: "Sim" },
          { id: "N", nome: "Não" }
        ],
      },
      cliente: {},
      autocompleteFilterValue: "",
      currentStep: 0,
      fieldError: {},
    };
  },
};
</script>

<style lang="scss" scoped>
#tabButtons {
  .v-btn {
    border-left: 3px solid #FFFFFF;
  }

  :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .v-btn {
    background-color: #BEDEFC !important;
    font-size: 0.8rem;

    &.active {
      background-color: #81B4EA !important;
      font-weight: 500;
    }
  }
}

::v-deep .v-chip {
  &.v-size--default {
    height: unset;
  }

  .v-chip__content {
    white-space: initial;
  }
}

// Corrige um bug introduzido no Vuetify v2.3.1 https://github.com/vuetifyjs/vuetify/issues/11553
::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
</style>
